import { Grid, Typography, Link } from '@mui/material';
import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


export default function About() {

    return (

        <Grid container xs={11} sm={8} md={7} lg={5} mb={4}>

          {/* Spacer */}
          <Grid item xs={12} sx={{height: 50}}>
            </Grid> 

            {/* Profile Pic */}
            <Grid item xs={2} sm={3}/>
            <Grid item xs={8} sm={6} 
                textAlign="center">
              <img src="profile.jpg" alt="profile" style={{width: "100%"}}/>
            </Grid>
            <Grid item xs={2} sm={3}/>

          {/* My name */}
            <Grid item xs={12}>
              <Typography mt={3}
                  sx={{
                    fontFamily: "Inter",
                    textAlign: "center",
                    lineHeight: 1.2,
                    fontWeight: 300,
                    fontSize: {xs:30, sm:30, md:36, lg:44}}}>
                      Simon d'Oelsnitz
              </Typography>

            </Grid>


            <Grid item xs={12} mt={5} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18}}}>
                        I am a Synthetic Biology Fellow at Harvard Medical School working at the
                        <a href="https://synbiohive.org/" target="_blank" rel="noopener noreferrer"> Synthetic Biology Hive </a>
                        and the
                        <a href="https://wyss.harvard.edu/team/postdoctoral-fellow/simon-doelsnitz/" target="_blank" rel="noopener noreferrer"> Wyss Institute</a>
                        . 
                        I have an undergraduate degree in Pharmacology from Stony Brook University.

                        From 2016 to 2021, I did my PhD at The University of Texas at Austin with Andy Ellington 
                        and Hal Alper where I used directed evolution to engineer proteins that
                        <a href="https://pubs.acs.org/doi/abs/10.1021/acssynbio.1c00402" target="_blank" rel="noopener noreferrer"> sense</a>
                        , 
                        <a href="https://www.nature.com/articles/s41589-022-01072-w" target="_blank" rel="noopener noreferrer"> produce</a>
                        , 
                        <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/ange.202100579" target="_blank" rel="noopener noreferrer"> incorporate</a>
                        , or
                        <a href="https://academic.oup.com/jimb/article/46/12/1715/5996814" target="_blank" rel="noopener noreferrer"> transport </a>
                        small molecules.

                        From 2021 to 2023, I implemented massively parallel reporter assays to 
                        <a href="https://www.researchsquare.com/article/rs-5861295/v1" target="_blank" rel="noopener noreferrer"> profile </a>
                        transcription factor libraries with the 
                        <a href="https://www.nist.gov/mml/bbd/cellular-engineering-group" target="_blank" rel="noopener noreferrer"> Cellular Engineering Group </a>
                        at the National Institute of Standards and Technology (NIST). 

                        Since 2023, I have lead a subgroup at Harvard Medical School focused on developing and applying chemical-responsive biosensors.
                        This Fall I will be applying to faculty positions.

                        <br/>
                        <br/>
                        My general expertise is in <u>protein engineering and chemical biology.</u> My specialized expertise is in repurposing bacterial transcription factors as chemical measurement tools to better 
                        understand and engineer protein-chemical interfaces. To do this, I use a combination of directed evolution, synthetic biology, bioinformatics, and parallel reporter assays.

                        

                        {/* My long-term research goals are two-fold:
                        <ol >
                          <li>Engineer transcription factors into routine sensors for analytical chemistry</li>
                            <ul style={{"list-style-type":"None", marginBottom:5}}>
                              <li><i>Just as enzymes are becoming routine catalysts for synthetic chemistry</i></li>
                            </ul>

                          <li>Map the ligand &amp; DNA specificity of all bacterial transcription factors</li>
                            <ul style={{"list-style-type":"None", marginBottom:2}}>
                                <li><i>To understand exactly how bacteria respond to chemistry</i></li>
                              </ul>
                        </ol>

                        If you're also interested in these things, feel free to reach out:  */}
                    </Typography>
            
            <Grid item xs={12} mt={1} textAlign={"right"}>

              <Link
                href="https://www.linkedin.com/in/simon-doelsnitz/"
                target="_blank">
                <LinkedInIcon sx={{ width: '50px', height: '50px' }} />
              </Link>
              <Link
                href="mailto:simonsnitz@gmail.com"
                target="_blank">
                <MailOutlineIcon sx={{ width: '50px', height: '50px', ml:3 }} />
              </Link>

            </Grid>

          </Grid>

        </Grid>

        

);
}